import request from '@/utils/request'

//完成列表
export function listBillDetail(params) {
  return request({
    url: '/api/system/mes/bill/work/order/listBillDetail',
    method: 'get',
    params
  })
}

//完成合计
export function sumListBillDetail(params) {
  return request({
    url: '/api/system/mes/bill/work/order/sumListBillDetail',
    method: 'get',
    params
  })
}

//修改工单明细的入库仓库
export function modifyInStore(data) {
  return request({
    url: '/api/system/mes/bill/work/order/modifyInStore',
    method: 'post',
    data
  })
}

//生成产品入库单
export function generateInStore(data) {
  return request({
    url: '/api/system/mes/bill/work/order/generateInStore',
    method: 'post',
    data
  })
}
